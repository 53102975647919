import React, { useState, useEffect } from "react"
import { GatsbyImage } from 'gatsby-plugin-image'

import Container from "./container"
import "./imageGridSection.css"
import "./button.css"

const ImageGridSection = ( { data, location } ) => {

  const [initialData, setInitialData] = useState([]);
  const [extendedData, setExtendedData] = useState([]);
  const [showAll, setShowAll] = useState(false);

  // Function to divide data into initialData and extendedData
  const divideData = () => {
    if (data.images.length > data.initial_count) {
      setInitialData(data.images.slice(0, data.initial_count));
      setExtendedData(data.images.slice(data.initial_count));
    } else {
      setShowAll(true);
      setInitialData(data.images);
    }
  };

  useEffect(() => {
    divideData();
  }, [data]);

  console.log('data.initial_count', data.initial_count);

  console.log('initialData', initialData);
  console.log('extendedData', extendedData);

    return (
      <Container className={`${location}-grid ${data.type}-grid image-grid-container`}>

        {initialData.map((image) => (
          <div className="single-image-wrapper show">

            <GatsbyImage
              className="image-container"
              objectFit="contain"
              image={image.image.childImageSharp.gatsbyImageData}
              alt={image.title}
            />

            {image.description &&
              <h4 className="image-desc">
                {image.description}
              </h4>
            }

          </div>
        ))}

        {data.button_text &&
          <div className={`showall-btn-container ${showAll ? "" : "show"}`}>
            <button className={`cta-btn ${data.button_style}`} onClick={() => setShowAll(true)}>
              <a>{data.button_text}</a>
            </button>
          </div>
        }

        {extendedData &&
          <>
            {extendedData.map((image) => (
              <div className={`single-image-wrapper ${showAll ? "show" : ""}`}>

                <GatsbyImage
                  className="image-container"
                  objectFit="contain"
                  image={image.image.childImageSharp.gatsbyImageData}
                  alt={image.title}
                />

                {image.description &&
                  <h4 className="image-desc">
                    {image.description}
                  </h4>
                }

              </div>
            ))}
          </>
        }

      </Container>
    )
  }

export default ImageGridSection