import * as React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import "./header.css"

const Header = ({ data, slug }) => {

  return (

    <div className={`page-header ${slug}-header ${data.overlay ? 'overlay' : '' }`}>

      <div className="header-info-container">
        <h1 className="page-title">{data.title}</h1>
        <ul id="breadcrumbs">
        {/* <li><Link to="/">Czamaninek</Link></li> */}
          {data.breadcrumbs &&
            data.breadcrumbs.map((breadcrumb) => (
            <li><Link to={breadcrumb.url}>{breadcrumb.title}</Link></li>
          ))}
          <li className="active">{data.title}</li>
        </ul>
      </div>

      <div className="header-img-container">
        <GatsbyImage
          image={data.image.childImageSharp.gatsbyImageData}
          alt={`${data.title} header`}
        />
      </div>

    </div>

  )
}

export default Header